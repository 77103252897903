import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useGetTracksListsQuery } from '@gqlTypes'
import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { type ReactElement } from 'react'

interface Props {
	name?: string
}

export const TrackInput = ({ name }: Props): ReactElement | null => {
	const { t } = useCustomTranslation('tracks:fields.trackId')
	const { data } = useGetTracksListsQuery()

	const trackPositions = data?.getTrackPositionList?.nodes ?? []
	const trackOffers = data?.getTrackOfferList?.nodes ?? []
	const items = [...trackPositions, ...trackOffers]

	if (items.length === 0) return null
	return (
		<AutocompleteSelect label={t('label')} noneValueLabel={t('noneValue')} name={name ?? 'trackId'} items={items} />
	)
}
