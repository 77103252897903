import { useEffect, type ReactElement } from 'react'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useGetUserOfferJobMatchingsQuery } from '@gqlTypes'
import { Container } from '@mui/material'
import { CardList } from '@features/lists/components/CardList'
import { JobMatchingListToolbar } from './JobMatchingListToolbar'
import { JobMatchingCard } from './JobMatchingCard'
import { EmptyJobMatchingList } from './EmptyJobMatchingList'
import { useParams } from 'react-router-dom'
import { useGetOfferTitle } from './useGetOfferTitle'

export const JobMatchingList = (): ReactElement => {
	const { trackOfferId = '', statusId = '' } = useParams<{ trackOfferId: string; statusId: string }>()
	const { t } = useCustomTranslation()
	useDocumentTitle(t('offers:documentTitle'))
	const variables = {
		limit: 12,
		filters: [
			{ key: 'trackOfferId', operation: '==', value: trackOfferId },
			{ key: 'statusId', operation: '==', value: statusId }
		],
		cursor: ''
	}
	const { loading, data, fetchMore, refetch } = useGetUserOfferJobMatchingsQuery({ variables })
	const { nodes = [], cursor = '', hasNextPage = false } = data?.getUserOfferJobMatchings ?? {}
	const label = useGetOfferTitle(trackOfferId)

	useEffect(() => {
		void refetch(variables)
	}, [trackOfferId, statusId])

	return (
		<Container maxWidth="xl">
			<CardList
				backRoute={'/offers'}
				toolbar={<JobMatchingListToolbar {...{ trackOfferId, statusId }} />}
				renderItem={(item) => <JobMatchingCard {...{ trackOfferId, statusId }} key={item.jobMatching.id} {...item} />}
				dataList={nodes}
				isLoading={loading}
				fetchMore={fetchMore}
				cursor={cursor}
				hasNextPage={hasNextPage}
				title={label}
				emptyComponent={<EmptyJobMatchingList />}
			/>
		</Container>
	)
}
