import { type GetArticlesQuery } from '@gqlTypes'
import { Box, Stack, Typography } from '@mui/material'
import { PictureCard } from '@shared/components/cards/PictureCard'
import { ChipList } from '@shared/components/ChipList'
import { t } from 'i18next'
import { type ReactElement } from 'react'
import CircleIcon from '@mui/icons-material/Circle'
import { Link } from 'react-router-dom'
import { generateRoute } from '../../../router/routes'
import { ArticlesRouter } from '../Articles.router'

interface Props {
	article: GetArticlesQuery['getArticles']['nodes'][0]
}

export const ArticleCard = ({ article }: Props): ReactElement => {
	const categories = article?.categories ?? []
	return (
		<Link
			to={{ pathname: generateRoute(ArticlesRouter.paths.update, { articleId: article.id }) }}
			style={{ display: 'inline-flex' }}
		>
			<Box>
				<PictureCard
					cardStyle={{
						width: 268,
						boxShadow: 3,
						borderRadius: 3,
						display: 'inline-block',
						height: '100%',
						'&:hover': { boxShadow: 10 }
					}}
					pictureStyle={{ height: 137, width: '100%' }}
					image={article.imageUrl}
				>
					<Stack direction="column" alignItems="flex-start" gap={1.5}>
						<Typography sx={{ fontSize: 14 }}>{article.title}</Typography>
						<ChipList
							items={categories}
							selectedItems={categories.map(({ id }) => id)}
							chipProps={{ size: 'small' }}
							gap={0.5}
						/>
						<Stack direction="row" gap={1} style={{ alignItems: 'center' }}>
							<CircleIcon sx={{ width: 10, height: 10 }} color={'secondary'} />
							<Typography sx={{ fontSize: '14px', color: 'grays.gray3' }}>
								{t('article:card.publicationDate') + ` ${article.creationDateLabel}`}
							</Typography>
						</Stack>
					</Stack>
				</PictureCard>
			</Box>
		</Link>
	)
}
