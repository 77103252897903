import { LeftBar } from '@features/lists/components/LeftBar'
import { type ReactElement } from 'react'
import { IconAction } from '@ui/icon/IconAction'
import AddIcon from '@mui/icons-material/Add'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { generateRoute } from '../../../router/routes'
import { ArticlesRouter } from '../Articles.router'
import { Link } from 'react-router-dom'

export const ArticleListFilterBar = (): ReactElement | null => {
	const { t } = useCustomTranslation()

	return (
		<LeftBar>
			<Link to={{ pathname: generateRoute(ArticlesRouter.paths.create) }} style={{ display: 'inline-flex' }}>
				<IconAction tooltip={t('article:actions.create')}>
					<AddIcon />
				</IconAction>
			</Link>
		</LeftBar>
	)
}
