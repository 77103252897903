import { useCustomTranslation } from '#translations/useCustomTranslation'
import { Typography } from '@mui/material'
import { BooleanToggleInput } from '@ui/form/BooleanToggleInput'
import { type ReactElement } from 'react'

export const IsExternalLinkInput = (): ReactElement => {
	const { t } = useCustomTranslation('article:form.fields.isExternalLink')

	return (
		<BooleanToggleInput
			TrueComponent={<Typography>{t('true')}</Typography>}
			FalseComponent={<Typography>{t('false')}</Typography>}
			name={'isExternalLink'}
		/>
	)
}
