import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useMeQuery } from '@gqlTypes'
import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { type ReactElement } from 'react'

interface Props {
	name?: string
}

export const CommunityLabelInput = ({ name }: Props): ReactElement | null => {
	const { t } = useCustomTranslation('organization:fields.communityLabel')
	const { data } = useMeQuery()

	const items = (data?.me?.organization?.communityLabels ?? []).map((label: string) => ({
		value: label,
		label
	}))

	if (items.length === 0) return null
	return (
		<AutocompleteSelect
			label={t('label')}
			name={name ?? 'communityLabel'}
			items={items}
			noneValueLabel={t('noneValue')}
		/>
	)
}
