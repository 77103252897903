import { type ChangeEvent, type ReactElement, useRef } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useController } from 'react-hook-form'

interface Props {
	onSelectFile: (files: FileList | null) => void
	localFile: FileList | null
	name: string
	buttonLabel: string
	text?: string
	accept?: string
}

export const LocalImageInput = ({
	onSelectFile,
	localFile,
	name,
	buttonLabel,
	text = '',
	accept = ''
}: Props): ReactElement => {
	const fileInputRef = useRef(null)
	const {
		field: { value }
	} = useController({ name })

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
		onSelectFile(event.target.files)
	}

	const file = localFile != null ? localFile.item(0) : null
	const imageUrl = file != null ? URL.createObjectURL(file) : value
	const hasImageUrl = imageUrl != null && imageUrl !== ''

	return (
		<Stack gap={2} alignItems="flex-start">
			{text !== '' ? <Typography>{text}</Typography> : null}
			<Box
				component="img"
				sx={{ width: hasImageUrl ? 400 : 0, borderRadius: 1, aspectRatio: 700 / 450 }}
				src={imageUrl}
			/>
			<Button sx={{ borderRadius: 15 }} component="label" variant="contained">
				<input
					ref={fileInputRef}
					hidden
					accept={accept !== '' ? accept : 'image/*'}
					multiple={false}
					type="file"
					onChange={handleFileChange}
				/>
				<Typography>{buttonLabel}</Typography>
			</Button>
		</Stack>
	)
}
