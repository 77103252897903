export interface ObjectWithId {
	id: string
}

/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getValue = (object: any, key: string): any => {
	return key
		.split('.')
		.reduce((currentObject, currentKey) => (currentObject != null ? currentObject[currentKey] : false), object)
}

export const pick = <T extends object, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> =>
	Object.fromEntries(keys.filter((key) => key in obj).map((key) => [key, obj[key]])) as Pick<T, K>

export const omit = <T extends object, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> => {
	// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
	keys.forEach((key) => delete obj[key])
	return obj
}

export const toArray = <T>(object: Record<string, T>): (T & ObjectWithId)[] => {
	return Object.entries(object).map(([key, value]: [string, T]) => ({ id: key, ...value }))
}
