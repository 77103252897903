import { AutocompleteSelect, type AutocompleteSelectProps } from '@ui/form/AutocompleteSelect'
import { type ReactElement } from 'react'
import { useOrganization } from '@domains/organization/useOrganization'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props extends Partial<AutocompleteSelectProps> {}

export const LocationSelect = (props: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { organization } = useOrganization()
	const type = (organization?.type ?? 'company') === 'company' ? 'employee' : 'student'
	const availableLocations = organization?.locations.map((bu: string) => ({ value: bu, label: bu })) ?? []

	if (availableLocations.length === 0) return null
	return (
		<AutocompleteSelect
			name="location"
			noneValueLabel={t(`organizationUsers:details.cards.tracks.form.${type}.location.none`)}
			items={availableLocations}
			label={t(`organizationUsers:details.cards.tracks.form.${type}.location.label`)}
			{...props}
		/>
	)
}
