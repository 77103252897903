import { ScreenId } from '@gqlTypes'
import { Redirect } from 'react-router-dom'
import { ArticleList } from './list/ArticlesList.page'
import { ArticleUpdator } from './form/update/ArticleUpdator'
import { ArticleCreator } from './form/creation/ArticleCreator'

enum Path {
	default = '/article',
	list = '/article/list',
	update = '/article/:articleId',
	create = '/article/new'
}

const routes = [
	{
		path: Path.list,
		children: <ArticleList />
	},
	{
		path: Path.create,
		children: <ArticleCreator />
	},
	{
		path: Path.update,
		children: <ArticleUpdator />
	},
	{
		path: Path.default,
		children: <Redirect to={Path.list} />
	}
]

export const ArticlesRouter = {
	roleId: ScreenId.Articles,
	paths: Path,
	routes
}
