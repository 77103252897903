import { useGqlSnackBar } from '@application/snackbar/useGqlSnackBar'
import { type ArticleInput, useUpdateArticleMutation } from '@gqlTypes'
import { type ReactElement } from 'react'
import { ArticleForm } from '../ArticleForm'
import { useParams } from 'react-router-dom'

export const ArticleUpdator = (): ReactElement => {
	const { articleId } = useParams<{ articleId: string }>()
	const { onError, onCompleted } = useGqlSnackBar('article:form.save')
	const [create, { loading: isSaving }] = useUpdateArticleMutation({ onError, onCompleted })
	return (
		<ArticleForm
			onError={onError}
			isSaving={isSaving}
			onSave={async (articleInput: ArticleInput) => {
				await create({ variables: { articleInput, articleId } })
				return { id: articleId }
			}}
		/>
	)
}
