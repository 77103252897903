import { type ReactElement, useState } from 'react'
import { type GetUserOfferListQuery } from '@gqlTypes'
import { IconButton, Checkbox, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { CandidateCardEditDialog } from './CandidateCardEditDialog'
import CommentModal from '@features/lists/components/modals/CommentModal/CommentModal'
import { useCandidateUpdate } from './useCandidateUpdate'

interface Props {
	offer: GetUserOfferListQuery['getUserOfferList']['nodes'][0]
	isSelected?: boolean
	selectRow?: () => void
	isHovered: boolean
}

export const CandidateCardActions = ({
	offer,
	isSelected = false,
	selectRow,
	isHovered
}: Props): ReactElement | null => {
	const [isOpen, setIsOpen] = useState(false)
	const { t } = useCustomTranslation()
	const { update } = useCandidateUpdate(offer.id)

	const isSelectable = selectRow != null && isSelected != null
	const iconColor = isHovered ? 'grays.gray2' : 'transparent'

	if (!isSelectable) return null
	return (
		<>
			<Tooltip title={t('students:actions.edit.tooltip')}>
				<IconButton
					onClick={() => {
						setIsOpen(true)
					}}
				>
					<EditIcon sx={{ color: iconColor }} />
				</IconButton>
			</Tooltip>
			<CandidateCardEditDialog
				offer={offer}
				handleClose={() => {
					setIsOpen(false)
				}}
				isOpen={isOpen}
			/>
			<Tooltip title={t('students:actions.select.tooltip')}>
				<Checkbox sx={{ color: iconColor }} checked={isSelected} onClick={selectRow} />
			</Tooltip>

			<CommentModal
				id={offer.id}
				defaultValue={offer.comment ?? ''}
				onSelect={(comment) => {
					update({ comment })
				}}
				isHovered={isHovered}
			/>
		</>
	)
}
