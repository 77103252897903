import { type ReactNode, type ReactElement } from 'react'
import { Box, Card } from '@mui/material'
import { Header } from '@ui/layout/Header'

interface Props {
	children: ReactNode
	title?: string
	actions?: ReactElement
}

export const CardWithHeader = ({ children, title = '', actions = <></> }: Props): ReactElement | null => {
	return (
		<Box>
			<Header title={title} action={actions} />
			<Card>{children}</Card>
		</Box>
	)
}
