import { type ReactElement } from 'react'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { Button, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useGenerateInterviewPdf } from './useGenerateInterviewPdf'

interface Props {
	interviewId: string
	pdfUrl: string
	hasGenerateRights: boolean
	hasPdfExport: boolean
	signatureStep: boolean
}

export const InterviewPdf = ({
	pdfUrl,
	interviewId,
	hasGenerateRights,
	hasPdfExport,
	signatureStep
}: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { isGeneratingPdf, generateInterviewPdf } = useGenerateInterviewPdf(interviewId)

	const isPDFGenerateButtonDisplayed = !signatureStep && hasGenerateRights

	if (!hasPdfExport) return null

	return (
		<Stack direction="row" spacing={2} alignItems="center">
			{pdfUrl !== '' ? (
				<Button variant="contained" color="secondary" href={pdfUrl} target="_blank">
					{t('translation:interviewDetailPage.pdf.open')}
				</Button>
			) : null}
			{isPDFGenerateButtonDisplayed ? (
				<LoadingButton
					loading={isGeneratingPdf}
					loadingPosition="start"
					variant="contained"
					onClick={generateInterviewPdf}
				>
					{t('translation:interviewDetailPage.pdf.generate')}
				</LoadingButton>
			) : null}
		</Stack>
	)
}
