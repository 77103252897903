import { useCustomTranslation } from '#translations/useCustomTranslation'
import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { type ReactElement } from 'react'

export const ContentLinkInput = (): ReactElement => {
	const { t } = useCustomTranslation()
	return (
		<InputTextFieldForm
			name="contentLink"
			rules={{
				required: t('form:errors.required')
			}}
			label={t('article:form.fields.contentLink.label')}
		/>
	)
}
