import { useCustomTranslation } from '#translations/useCustomTranslation'
import { DefaultLayout } from '@ui/layout/DefaultLayout'
import { type ReactElement } from 'react'
import { type OnSaveReturn, useUpsertArticleForm } from './useUpsertArticleForm'
import { FormProvider } from 'react-hook-form'
import { ExitFormWarning } from '@ui/form/ExitFormWarning'
import { Stack } from '@mui/material'
import { ContentLinkInput } from './redirection/ContentLinkInput'
import { ContentInput } from './content/ContentInput'
import { LinkLabelInput } from './content/LinkLabelInput'
import { CategoriesInput } from './search/CategoriesInput'
import { TitleInput } from './content/TitleInput'
import { IsExternalLinkInput } from './redirection/IsExternalLinkInput'
import { LocalImageInput } from '@ui/form/LocalImageInput'
import { TrackInput } from '@domains/tracks/fields/TrackInput'
import { CommunityLabelInput } from '@domains/communityLabels/CommunityLabelInput'
import { StatusInput } from '@domains/organization/fields/StatusInput'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { CancelFormButton } from '@ui/form/buttons/CancelFormButton'
import { UpdateFormButton } from '@ui/form/buttons/UpdateFormButton'
import { Masonry } from '@mui/lab'
import { CardWithHeader } from '@ui/cards/CardWithHeader'
import BackButton from '@ui/buttons/BackButton'
import { ArticlesRouter } from '../Articles.router'
import { Title } from '@ui/typography/Title'
import { type ArticleInput } from '@gqlTypes'
import { KeywordsInput } from './search/KeywordsInput'
import { BusinessUnitSelect } from '@domains/businessUnit/BusinessUnitSelect'
import { LocationSelect } from '@domains/location/LocationSelect'
import { useHistory } from 'react-router-dom'
import { OrganizationLanguageField } from '@domains/language/OrganizationLanguageField'

interface Props {
	onSave: (articleInput: ArticleInput) => Promise<OnSaveReturn>
	isSaving: boolean
	onError: () => void
	validationButtonLabel?: string
}

export const ArticleForm = ({ onSave, isSaving, onError, validationButtonLabel }: Props): ReactElement => {
	const { t } = useCustomTranslation('article:form')
	useDocumentTitle(t('pageTitle'))
	const history = useHistory()

	const { formMethods, imageLocalFile, setImageLocalFile, save } = useUpsertArticleForm({ onSave })

	const {
		handleSubmit,
		formState: { isDirty }
	} = formMethods

	const isActionsDisabled = !isDirty && imageLocalFile == null

	return (
		<DefaultLayout
			containerStyle={{ display: 'block' }}
			title={
				<>
					<BackButton route={ArticlesRouter.paths.list} />
					<Title text={t('pageTitle')} />
				</>
			}
			bottomRight={
				<Stack direction="row" spacing={3}>
					<CancelFormButton
						disabled={isActionsDisabled}
						onClick={() => {
							history.push(ArticlesRouter.paths.list)
						}}
					/>
					<UpdateFormButton
						disabled={isActionsDisabled}
						loading={isSaving}
						onClick={handleSubmit(save, onError)}
						label={validationButtonLabel}
					/>
				</Stack>
			}
		>
			<FormProvider {...formMethods}>
				<ExitFormWarning isModified={isDirty} />

				<Masonry columns={2} spacing={4}>
					<CardWithHeader>
						<Stack spacing={2} padding={2}>
							<OrganizationLanguageField />
							<TitleInput />
							<ContentInput />
							<LinkLabelInput />
						</Stack>
					</CardWithHeader>
					<CardWithHeader title={t('cards.categorization')}>
						<Stack spacing={2} padding={2}>
							<CategoriesInput />
							<KeywordsInput />
						</Stack>
					</CardWithHeader>
					<CardWithHeader title={t('cards.behavior')}>
						<Stack spacing={2} padding={2}>
							<ContentLinkInput />
							<IsExternalLinkInput />
						</Stack>
					</CardWithHeader>
					<CardWithHeader title={t('cards.target')}>
						<Stack spacing={2} padding={2}>
							<TrackInput name="restrictions.trackId" />
							<LocationSelect name="restrictions.locationId" />
							<BusinessUnitSelect name="restrictions.businessUnit" />
							<CommunityLabelInput name="restrictions.communityLabel" />
							<StatusInput name="restrictions.status" />
						</Stack>
					</CardWithHeader>
					<CardWithHeader title={t('cards.image')}>
						<Stack spacing={2} padding={2}>
							<LocalImageInput
								onSelectFile={setImageLocalFile}
								localFile={imageLocalFile}
								name={'imageUrl'}
								buttonLabel={t('fields.uploadedImageUrl.button')}
								text={t('fields.uploadedImageUrl.text')}
								accept=".jpg"
							/>
						</Stack>
					</CardWithHeader>
				</Masonry>
			</FormProvider>
		</DefaultLayout>
	)
}
