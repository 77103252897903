import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useMeQuery } from '@gqlTypes'
import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { type ReactElement } from 'react'

interface Props {
	name?: string
}

export const StatusInput = ({ name }: Props): ReactElement | null => {
	const { t } = useCustomTranslation('organization:fields.status')
	const { data } = useMeQuery()

	const organization = data?.me?.organization
	const offerStatuses = organization?.offerStatusObject ?? []
	const positionStatuses = organization?.positionStatus ?? []

	const items = [...offerStatuses, ...positionStatuses].map(({ id, label }) => ({
		value: id,
		label
	}))

	if (items.length === 0) return null
	return <AutocompleteSelect label={t('label')} noneValueLabel={t('noneValue')} name={name ?? 'status'} items={items} />
}
