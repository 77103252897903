import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useMeQuery } from '@gqlTypes'
import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { type ReactElement } from 'react'

interface Props {
	name?: string
	label?: string
}

export const OrganizationLanguageField = ({ name, label }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { data } = useMeQuery()
	const items = (data?.me?.organization?.languages ?? []).map((item) => ({ value: item.id, label: item.label }))
	const fieldName = name ?? 'language'

	if (items.length === 1) return null

	return (
		<AutocompleteSelect
			label={label ?? t('myAccount:generalInformations.fields.language.title')}
			name={fieldName}
			items={items}
			rules={{ required: t('form:errors.required') }}
			disableClearable
		/>
	)
}
