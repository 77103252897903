import { useCustomTranslation } from '#translations/useCustomTranslation'
import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { type ReactElement } from 'react'

export const ContentInput = (): ReactElement => {
	const { t } = useCustomTranslation()
	return (
		<InputTextFieldForm
			name="content"
			label={t('article:form.fields.content.label')}
			multiline
			tooltip={t('article:form.fields.content.tooltip')}
		/>
	)
}
