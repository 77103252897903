import { InfoCardContent } from '@ui/core/InfoCardContent'
import CardLayout from '@ui/core/CardLayout'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type ReactElement } from 'react'

interface Props {
	content?: string
}

export const CommunicationKeyCard = ({ content = '' }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()

	if (content === '') return null

	return (
		<CardLayout title={t('translation:candidates.profile.communicationKeys.title')}>
			<InfoCardContent>{content}</InfoCardContent>
		</CardLayout>
	)
}
