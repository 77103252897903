import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useGetPublicArticleCategoriesQuery } from '@gqlTypes'
import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { type ReactElement } from 'react'

export const CategoriesInput = (): ReactElement => {
	const { t } = useCustomTranslation('article:form.fields.categoryIds')
	const { data } = useGetPublicArticleCategoriesQuery()
	const items = (data?.getPublicArticleCategories ?? []).map((item) => ({ value: item.id, label: item.name }))
	const name = 'categoryIds'

	return (
		<AutocompleteSelect
			label={t('label')}
			name={name}
			items={items}
			disableClearable
			isMultiple
			rules={{
				validate: (values: string[]) => (values.length === 0 ? t('error') : true),
				required: true
			}}
		/>
	)
}
