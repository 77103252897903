import { useCustomTranslation } from '#translations/useCustomTranslation'
import { LoadingButton, type LoadingButtonProps } from '@mui/lab'
import { type ReactElement } from 'react'

interface Props extends LoadingButtonProps {
	label?: string
}

export const UpdateFormButton = ({ label, ...buttonProps }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	return (
		<LoadingButton variant="contained" color="secondary" {...buttonProps}>
			{label ?? t('form:button.update.label')}
		</LoadingButton>
	)
}
