import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { type ReactNode, type ReactElement } from 'react'
import { useController } from 'react-hook-form'

interface Props {
	TrueComponent: ReactNode
	FalseComponent: ReactNode
	name: string
}

export const BooleanToggleInput = ({ TrueComponent, FalseComponent, name }: Props): ReactElement => {
	const {
		field: { value, onChange }
	} = useController({ name })

	return (
		<ToggleButtonGroup
			size="small"
			color="primary"
			value={value}
			exclusive
			onChange={(_, v) => {
				onChange(v)
			}}
		>
			<ToggleButton value={true}>{TrueComponent}</ToggleButton>
			<ToggleButton value={false}>{FalseComponent}</ToggleButton>
		</ToggleButtonGroup>
	)
}
