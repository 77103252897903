import { type ReactElement, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import { Box, Stack, useTheme } from '@mui/material'

interface Props {
	score: number
	startLabel: string
	endLabel: string
	description: string
}

export const DimensionSlider = ({ score, startLabel, endLabel, description }: Props): ReactElement => {
	const theme = useTheme()
	const [isExpanded, setExpanded] = useState(false)

	const barWidth = Math.abs(score) * 100
	const marginLeft = score > 0 ? '50%' : `${50 - barWidth}%`

	return (
		<div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'stretch',
					alignItems: 'center'
				}}
			>
				<div
					style={{
						flex: 1
					}}
				>
					<Stack sx={{ my: 0.5 }}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'flex-end',
								justifyContent: 'space-between',
								marginBottom: 0.4
							}}
						>
							{startLabel != null ? (
								<Typography
									sx={{
										wordBreak: 'break-word'
									}}
									component="label"
									variant="body2"
								>
									{startLabel}
								</Typography>
							) : null}
							{endLabel != null ? (
								<Typography component="label" variant="body2">
									{endLabel}
								</Typography>
							) : null}
						</Box>
						<Box
							sx={{
								position: 'relative',
								width: '100%',
								height: 8,
								backgroundColor: '#ECE4E4',
								borderRadius: 4
							}}
						>
							<Box
								sx={{
									position: 'absolute',
									top: 0,
									bottom: 0,
									left: marginLeft,
									width: `${barWidth}%`,
									backgroundColor: theme.palette.secondary.main,
									borderRadius: 4
								}}
							/>
						</Box>
					</Stack>
				</div>
				{description !== '' ? (
					<IconButton
						style={{
							marginLeft: 10,
							color: 'gray'
						}}
						size="small"
						onClick={() => {
							setExpanded(!isExpanded)
						}}
					>
						<HelpOutlineIcon />
					</IconButton>
				) : null}
			</div>
			<Collapse in={isExpanded}>
				<Typography
					variant="body2"
					style={{
						marginTop: 15,
						marginBottom: 15
					}}
				>
					{description}
				</Typography>
			</Collapse>
		</div>
	)
}
