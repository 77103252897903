import { useGqlSnackBar } from '@application/snackbar/useGqlSnackBar'
import { type ArticleInput, useCreateArticleMutation } from '@gqlTypes'
import { type ReactElement } from 'react'
import { ArticleForm } from '../ArticleForm'
import { useCustomTranslation } from '#translations/useCustomTranslation'

export const ArticleCreator = (): ReactElement => {
	const { t } = useCustomTranslation()

	const { onError, onCompleted } = useGqlSnackBar('article:form.save')
	const [create, { loading: isSaving }] = useCreateArticleMutation({ onError, onCompleted })
	return (
		<ArticleForm
			onError={onError}
			isSaving={isSaving}
			onSave={async (articleInput: ArticleInput) => {
				const response = await create({ variables: { articleInput } })
				return { id: response.data?.createArticle.id ?? '' }
			}}
			validationButtonLabel={t('article:form.buttons.create')}
		/>
	)
}
