import { AutocompleteSelect, type AutocompleteSelectProps } from '@ui/form/AutocompleteSelect'
import { type ReactElement } from 'react'
import { useOrganization } from '@domains/organization/useOrganization'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props extends Partial<AutocompleteSelectProps> {}

export const BusinessUnitSelect = (props: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { organization } = useOrganization()
	const type = (organization?.type ?? 'company') === 'company' ? 'employee' : 'student'
	const availableBusinessUnits = organization?.businessUnits.map((bu: string) => ({ value: bu, label: bu })) ?? []

	if (availableBusinessUnits.length === 0) return null
	return (
		<AutocompleteSelect
			name="businessUnit"
			noneValueLabel={t(`organizationUsers:details.cards.tracks.form.${type}.businessUnit.none`)}
			items={availableBusinessUnits}
			label={t(`organizationUsers:details.cards.tracks.form.${type}.businessUnit.label`)}
			{...props}
		/>
	)
}
