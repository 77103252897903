import { omit, pick } from '#helpers/object.helper'
import { type ArticleInput, useGetArticleQuery, useMeQuery } from '@gqlTypes'
import { useStorage } from '@infrastructure/useStorage'
import { useEffect, useState } from 'react'
import { useForm, type UseFormMethods } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { ArticlesRouter } from '../Articles.router'

interface UseUpdateArticleFormReturn {
	save: (articleInput: FormValues) => Promise<void>
	formMethods: UseFormMethods<ArticleInput>
	setImageLocalFile: (file: FileList | null) => void
	imageLocalFile: FileList | null
}

export interface OnSaveReturn {
	id: string
}

interface Props {
	onSave: (articleInput: ArticleInput) => Promise<OnSaveReturn>
}

interface FormValues extends ArticleInput {
	imageUrl: string
}

export const useUpsertArticleForm = ({ onSave }: Props): UseUpdateArticleFormReturn => {
	const { articleId } = useParams<{ articleId?: string }>()
	const { data } = useGetArticleQuery({ variables: { articleId: articleId ?? 'new' } })
	const article = data?.getArticle
	const { data: meData } = useMeQuery()
	const { uploadFiles } = useStorage()
	const history = useHistory()

	const [imageLocalFile, setImageLocalFile] = useState<FileList | null>(null)

	const defaultValues: FormValues = {
		categoryIds: [],
		contentLink: '',
		isExternalLink: false,
		restrictions: {
			businessUnit: '',
			communityLabel: '',
			locationId: '',
			status: '',
			trackId: ''
		},
		title: '',
		content: '',
		linkLabel: '',
		language: '',
		keywords: '',
		imageUrl: ''
	}

	const formMethods = useForm<ArticleInput>({ mode: 'onChange', defaultValues })
	const { reset } = formMethods

	const save = async (articleValues: FormValues): Promise<void> => {
		reset(articleValues)
		const article = await onSave({ ...omit(articleValues, 'imageUrl') })

		if (imageLocalFile != null) {
			await uploadFiles(
				imageLocalFile,
				`organizations/${meData?.me?.organization?.id ?? ''}/articles/${article.id}/image`,
				false,
				'banner.jpg'
			)
		}
		history.push(ArticlesRouter.paths.list)
	}

	useEffect(() => {
		if (article == null) return
		reset({
			...pick(
				article,
				'title',
				'content',
				'linkLabel',
				'categoryIds',
				'contentLink',
				'isExternalLink',
				'restrictions',
				'language',
				'imageUrl'
			),
			keywords: article.keywordsString
		})
	}, [reset, article])

	return {
		save,
		formMethods,
		imageLocalFile,
		setImageLocalFile
	}
}
