import { type Dispatch, type SetStateAction, useState } from 'react'
import { setUserBContext } from '@shared/store/actions/userB.action'
import { setRoleContext } from '@shared/store/actions/role.action'
import { useDispatch } from 'react-redux'
import Bugsnag from '@bugsnag/js'
import { setInterviewFields } from '@shared/store/actions/interviewFields.action'
import { indexByProperty } from '../../helper/array.helper'
import { type Exact, type MeQuery, useMeLazyQuery } from '@gqlTypes'
import i18next from 'i18next'
import { type LazyQueryExecFunction } from '@apollo/client'

export const AuthStates = {
	anonymous: 'anonymous',
	firebaseAuthenticated: 'firebaseAuthenticated',
	emagemeAuthenticated: 'emagemeAuthenticated',
	onlyMobile: 'onlyMobile'
}

interface Return {
	me: LazyQueryExecFunction<MeQuery, Exact<Record<string, never>>>
	loadUserContext: boolean
	authState: string
	language: string
	setAuthState: Dispatch<SetStateAction<string>>
	setLoadUserContext: Dispatch<SetStateAction<boolean>>
}

export const useAuth = (): Return => {
	const [loadUserContext, setLoadUserContext] = useState(true)
	const [authState, setAuthState] = useState(AuthStates.anonymous)
	const [language, setLanguage] = useState('fr')
	const dispatch = useDispatch()

	const onCompleted = async (data: MeQuery): Promise<void> => {
		const me = data.me
		let {
			id = '',
			active,
			firstname = '',
			lastname = '',
			visioLink = '',
			isRestrictedAccess = true,
			language,
			role = {}
		} = me ?? {}
		console.log({ id: me?.id })
		language = language ?? ''
		active = active ?? false
		Bugsnag.addMetadata('User', 'data', me)
		if (me != null && active) {
			Bugsnag.leaveBreadcrumb('UserB found and active')
			setAuthState(me.isMobileOnly ? AuthStates.onlyMobile : AuthStates.emagemeAuthenticated)
			setLanguage(language)
		} else {
			Bugsnag.leaveBreadcrumb('UserB found but deactivate')
		}

		// Indexing in front end en to prevent JSON typing in gql
		const indexedFields = indexByProperty(me?.organization?.interviewFields ?? [], 'id')
		dispatch(setRoleContext(role))
		dispatch(setInterviewFields(indexedFields))
		dispatch(setUserBContext({ id, active, firstname, lastname, visioLink, isRestrictedAccess }))

		setLoadUserContext(false)
		await i18next.changeLanguage(language)
	}

	const onError = (): void => {
		setLoadUserContext(false)
	}

	const [me] = useMeLazyQuery({ fetchPolicy: 'cache-and-network', onCompleted, onError })

	return { me, loadUserContext, authState, language, setAuthState, setLoadUserContext }
}
