import { useCustomTranslation } from '#translations/useCustomTranslation'
import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { type ReactElement } from 'react'

export const KeywordsInput = (): ReactElement => {
	const { t } = useCustomTranslation()
	return (
		<InputTextFieldForm
			name="keywords"
			label={t('article:form.fields.keywords.label')}
			tooltip={t('article:form.fields.keywords.tooltip')}
		/>
	)
}
