import { RiskStatusIds, useGetRisksQuery } from '@gqlTypes'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { RiskLabel } from './RiskLabel'
import { type ReactElement } from 'react'
import { indexById } from '#helpers/array.helper'

interface Props {
	onChange: (...event: unknown[]) => void
	value: string
	positionRiskId: string
}

export const RiskRadioGroup = ({ onChange, value, positionRiskId }: Props): ReactElement | null => {
	const { loading, data } = useGetRisksQuery()

	if (loading) return null
	const riskById = indexById(data?.getRisks ?? [])
	const highRisk = riskById[RiskStatusIds.High]
	const removed = riskById[RiskStatusIds.Removed]

	if (highRisk == null || removed == null) return null
	const isHighRisk = positionRiskId === RiskStatusIds.High

	return (
		<RadioGroup row onChange={onChange} value={value}>
			<FormControlLabel
				value={RiskStatusIds.High}
				control={
					<Radio
						onClick={() => {
							onChange(RiskStatusIds.High)
						}}
					/>
				}
				label={<RiskLabel risk={highRisk} />}
			/>
			{isHighRisk ? (
				<FormControlLabel
					value={RiskStatusIds.Removed}
					control={
						<Radio
							onClick={() => {
								onChange(RiskStatusIds.Removed)
							}}
						/>
					}
					label={<RiskLabel risk={removed} />}
				/>
			) : null}
		</RadioGroup>
	)
}
